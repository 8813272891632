<template>
  <div v-html="data.description.split('\n').join('<br />')" />
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
a
  color: $success
</style>
